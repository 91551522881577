import React from 'react'
import { Link } from 'gatsby'
import styles from 'components/article/article-upsell.module.css'

export default function NextPrev({ next, prev, nextLabel, prevLabel }) {
  return (
    <aside className="grid">
      <div className="grid-row-full-width">
        {prev && (
          <Link
            to={prev.fields.path}
            rel="prev"
            className={styles.articleLinkPrev}
          >
            <div>
              <small>{prevLabel}</small>
            </div>
            <span>{String.fromCharCode(11104)}</span>
            <span className={styles.articleLinkText}>
              {prev.frontmatter.title}
            </span>
          </Link>
        )}
        {next && (
          <Link
            to={next.fields.path}
            rel="next"
            className={styles.articleLinkNext}
          >
            <div>
              <small>{nextLabel}</small>
            </div>
            <span className={styles.articleLinkText}>
              {next.frontmatter.title}
            </span>
            <span>{String.fromCharCode(11106)}</span>
          </Link>
        )}
      </div>
    </aside>
  )
}