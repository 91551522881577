import React from 'react'
import ArticleContent from 'components/article/article-content'
import ArticleHeader from 'components/article/article-header'
import ArticleMeta from 'components/article/article-meta'
import ArticleUpsell from 'components/article/article-upsell'

const Article = ({
  title,
  description,
  summary,
  body,
  path,
  displayTime,
  publishTime,
  modifiedTime,
  expiredTime,
  imagePath,
  imageAlt,
  imageFluid,
  nextArticle,
  prevArticle
}) => {
  return (
    <article>
      <ArticleMeta
        title={title}
        description={description}
        publishTime={publishTime}
        modifiedTime={modifiedTime}
        expiredTime={expiredTime}
        imagePath={imagePath}
        imageAlt={imageAlt}
        path={path}
      />
      <ArticleHeader
        title={title}
        summary={summary}
        image={imageFluid}
        displayTime={displayTime}
      />
      <ArticleContent>
        {body}
      </ArticleContent>
      <ArticleUpsell
        next={nextArticle}
        prev={prevArticle}
        nextLabel="Next"
        prevLabel="Prev"
      />
    </article>
  )
}

export default Article
