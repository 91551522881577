import React from 'react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import styles from './article-content.module.css'

const ArticleContent = ({ children }) => (
  <div className={styles.articleContent}>
    <MDXRenderer>
      {children}
    </MDXRenderer>
  </div>
)

export default ArticleContent