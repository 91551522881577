import React from 'react'
import Image from 'gatsby-image'
import styles from './article-header.module.css'

const ArticleHeader = ({ title, summary, displayTime, image }) => {
  return (
    <header className={styles.articleHeader}>
      <div className="grid">
        {image && (
          <Image
            className={styles.articleHeaderImage}
            fluid={image}
          />
        )}
        <time className={styles.articleHeaderTime}>{displayTime}</time>
        <h1 className={styles.articleHeaderTitle}>{title}</h1>
        {summary && <p className={styles.articleHeaderSummary}>{summary}</p>}
      </div>
    </header >
  )
}

export default ArticleHeader
