import React from 'react'
import Helmet from 'react-helmet'
import useSite from 'hooks/use-site'

/**
 * Article (post/project) specific HTML metadata
 */
const ArticleMeta = (props) => {
  const { siteMetadata } = useSite()

  const {
    title,
    description,
    imagePath,
    imageAlt,
    path,
    publishTime,
    modifiedTime,
    expiredTime
  } = props

  return (
    <React.Fragment>
      <Helmet titleTemplate={`%s · ${siteMetadata.title}`}>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`${siteMetadata.canonicalUrl}${path}`} />
        <meta property="og:article:publish_time" content={publishTime} />
        <meta property="og:article:author" content={siteMetadata.author} />
        {modifiedTime && <meta property="og:article:modified_time" content={modifiedTime} />}
        {expiredTime && <meta property="og:article:expired_time" content={expiredTime} />}
      </Helmet>
      <ArticleImage imagePath={imagePath} imageAlt={imageAlt} />
    </React.Fragment>
  )
}

const ArticleImage = ({ imagePath, imageAlt }) => {
  const { siteMetadata } = useSite()

  return imagePath ? (
    <Helmet>
      <meta property="og:image" content={`${siteMetadata.canonicalUrl}${imagePath}`} />
      <meta property="og:image:width" content="400" />
      <meta property="og:image:height" content="400" />
      <meta property="og:image:type" content="image/jpeg" />
      {imageAlt && <meta property="og:image:alt" content={imageAlt} />}
    </Helmet>
  ) : null
}

export default ArticleMeta