import React from 'react'
import { graphql } from 'gatsby'
import Site from 'components/site'
import Article from 'components/article'

const Post = ({ data }) => {
  const { mdx } = data
  let imageFluid, imagePath, imageAlt = null

  if (mdx.frontmatter.image) {
    imageFluid = mdx.frontmatter.imageFluid.childImageSharp.fluid
    imagePath = mdx.frontmatter.image.childImageSharp.fixed.src
    imageAlt = mdx.frontmatter.imageAlt
  }

  return (
    <Site variant="dark">
      <Article
        title={mdx.frontmatter.title}
        description={mdx.frontmatter.description}
        summary={mdx.frontmatter.summary}
        displayTime={mdx.frontmatter.displayTime}
        publishTime={mdx.frontmatter.publishTime}
        modifiedTime={mdx.frontmatter.modifiedTime}
        expiredTime={mdx.frontmatter.expiredTime}
        path={mdx.fields.path}
        body={mdx.body}
        nextArticle={null}
        prevArticle={null}
        imageFluid={imageFluid}
        imagePath={imagePath}
        imageAlt={imageAlt}
      />
    </Site>
  )
}

export default Post

export const query = graphql`
  query BlogPost($path: String!) {
    mdx(fields: {path: {eq: $path } }) {
      id
      body
      fields {
        path
      }
      frontmatter {
        title
        description
        summary
        displayTime: publishTime(formatString: "MMMM Do, YYYY")
        publishTime
        modifiedTime
        expiredTime
        imageAlt
        imageFluid: image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image {
          childImageSharp {
            fixed(width: 400, height: 400, toFormat: JPG)  {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
